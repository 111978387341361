import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  /* private loaderSubject = new Subject<boolean>();
  loaderState$ = this.loaderSubject.asObservable(); */
  subject = new Subject<boolean>();

  constructor() {}

  /* show() {
    this.loaderSubject.next(true);
  }

  hide() {
    this.loaderSubject.next(false);
  } */
}
