<div>
    <div class="dis-grid showHeader" id="header" [ngClass]="showHeader ? 'showHeader' : 'hideHeader'">
        <div class="ham-menu">
            <div class="btn-icon" (click)="showMenu()">
                <div class="icon"></div>
            </div>
        </div>
        <div class="nav-logo">
            <a routerLink="/">
                <img src="assets/images/logo.png" alt="LOGO AMI LOS CABOS" />
            </a>
        </div>
        <div class="nav-links-ami">
            <nav class="main-navigation">
                <a class="desc desc-pr">AMI<sup>&#174;</sup> DMC</a>
                <a (click)="scrollToTop()" routerLink="/" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">{{ "header.menu" | translate }}</a>
                <a (click)="scrollToTop()" routerLink="/about" routerLinkActive="active">{{ "header.menu1" | translate
                    }}</a>
                <a (click)="scrollToTop()" routerLink="/services" routerLinkActive="active">{{ "header.menu3" |
                    translate
                    }}</a>
                <a (click)="scrollToTop()" routerLink="/company" routerLinkActive="active">{{ "header.menu2" | translate
                    }}</a>
                <a (click)="scrollToTop()" routerLink="/wellness" routerLinkActive="active">{{ "header.menu4" |
                    translate
                    }}</a>
                <a (click)="scrollToTop()" routerLink="/romance" routerLinkActive="active">{{ "header.menu5" | translate
                    }}</a>
                <a (click)="scrollToTop()" routerLink="/leisure" routerLinkActive="active">{{ "header.menu9" | translate
                    }}</a>
                <!--BOTON CARRITO DE COMPRAS-->
                <!--  <div class="nav-cart">
                    <button data-bs-toggle="popover" data-bs-container="body" data-bs-placement="bottom"
                        data-bs-trigger="focus" data-bs-html="true" [attr.data-bs-content]="myCartElement()"
                        data-bs-custom-class="custom-popover">
                        <i class="fa-solid fa-cart-shopping"></i>
                    </button> 
                </div> -->
            </nav>
        </div>
        <div class="nav-links-cabos">

            <nav class="main-navigation">
                <a class="desc">LOS CABOS</a>
                <div class="dropdown-ctn" *ngFor="let item of navItems">
                    <button class="dropbtn" [routerLink]="item.link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">{{item.text}}</button>
                    <div class="dropdown-content">
                        <div class="item-lvl-1" *ngFor="let lvl1 of item.items">
                            <a [fragment]="lvl1.fragment" [routerLink]="item.link + lvl1.link">{{lvl1.text}}</a>
                            <i class="fa-solid fa-chevron-right" *ngIf="lvl1.subitems && lvl1.subitems.length>0"></i>
                            <div class="dropdown-content-2">
                                <div *ngFor="let lvl2 of lvl1.subitems">
                                    <a [routerLink]="item.link + lvl2.link">{{lvl2.text}}</a>
                                    <!-- <i class="fa-solid fa-chevron-right"></i> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown">
                    <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{currentLanguage.icon}}&nbsp;<span> {{currentLanguage.text}} &nbsp;</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" (click)="useLanguage('en')"> 🇺🇸&emsp;{{ "header.en" | translate
                                }}
                            </a></li>
                        <li><a class="dropdown-item" (click)="useLanguage('es')"> 🇲🇽&emsp;{{ "header.es" | translate
                                }}
                            </a></li>
                    </ul>
                </div>
            </nav>
        </div>
        <!--  <div class="nav-plan-trip">
            <i class="fa-solid fa-plane d-block"></i>
            <strong>{{ 'header.menu6' | translate }}</strong>
        </div> -->
    </div>
    <div class="menu">
        <div class="close-menu">
            <i class="fa-solid fa-xmark" (click)="showMenu()"></i>
        </div>
        <div class="dis-flex">
            <div class="nav-language">
                <div class="dropdown">
                    <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{currentLanguage.icon}}<span>{{currentLanguage.text}}</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" (click)="useLanguage('en')"> 🇺🇸&emsp;{{ "header.en" | translate
                                }}
                            </a></li>
                        <li><a class="dropdown-item" (click)="useLanguage('es')"> 🇲🇽&emsp;{{ "header.es" | translate
                                }}
                            </a></li>
                    </ul>
                </div>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                        type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">AMI DMC</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                        type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">LOS
                        CABOS</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                    tabindex="0">
                    <ul class="d-flex flex-column">
                        <a class="mobile-menu" (click)="showMenu()" routerLink="/" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{ "header.menu" | translate }}</a>
                        <a class="mobile-menu" (click)="showMenu()" routerLink="/about" routerLinkActive="active">{{
                            "header.menu1"
                            | translate }}</a>
                        <a class="mobile-menu" (click)="showMenu()" routerLink="/services" routerLinkActive="active">{{
                            "header.menu3" | translate }}</a>
                        <a class="mobile-menu" (click)="showMenu()" routerLink="/company" routerLinkActive="active">{{
                            "header.menu2" | translate }}</a>
                        <a class="mobile-menu" (click)="showMenu()" routerLink="/wellness" routerLinkActive="active">{{
                            "header.menu4" | translate }}</a>
                        <a class="mobile-menu" (click)="showMenu()" routerLink="/romance" routerLinkActive="active">{{
                            "header.menu5" | translate }}</a>
                        <a class="mobile-menu" (click)="showMenu()" routerLink="/leisure" routerLinkActive="active">{{
                            "header.menu9" | translate }}</a>
                    </ul>
                </div>
                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                    tabindex="0">
                    <i class="fa-solid fa-arrow-left back" (click)="navBack()" *ngIf="currentItem1"></i>
                    <ul class="d-flex flex-column">
                        <div style="display: contents;" *ngIf="!currentItem1">
                            <a class="mobile-menu-dinamic" *ngFor="let item of navItems">
                                <span (click)="showMenu()" [routerLink]="item.link">{{ item.text }}</span>
                                <i class="fa-solid fa-arrow-right" (click)="selectItem(item,1)" *ngIf="item.items"></i>
                            </a>
                        </div>
                        <div style="display: contents;" *ngIf="currentItem1 && !currentItem2">
                            <a class=" mobile-menu-dinamic" *ngFor="let item of currentItem1.items">
                                <span (click)="showMenu()" [routerLink]="currentItem1.link + item.link">{{ item.text
                                    }}</span>
                                <i class="fa-solid fa-arrow-right" (click)="selectItem(item,2)"
                                    *ngIf="item.subitems"></i>
                            </a>
                        </div>
                        <div style="display: contents;" *ngIf="currentItem2 && currentItem2.subitems">
                            <a class="mobile-menu-dinamic" *ngFor="let item of currentItem2.subitems"
                                [routerLink]="currentItem1.link + item.link">
                                <span (click)="showMenu()" [routerLink]="currentItem1.link + item.link">{{ item.text
                                    }}</span>
                            </a>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>