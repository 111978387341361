import { Component, OnInit } from '@angular/core';
import { LoaderService } from './services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  scrollingInto: string | null = '';
  loader: boolean = true;

  constructor(private loaderServ: LoaderService) {
    this.loaderServ.subject.subscribe((value) => {
      this.loader = value;
    });
  }

  scroll($event: any) {}

  ngOnInit() {
    window.addEventListener('scroll', function () {
      if (window.scrollY < 0) {
        window.scrollTo(0, 0); // Fuerza la posición de desplazamiento a la parte superior
      }
    });
    setTimeout(() => {
      this.loaderServ.subject.next(false);
    }, 3000);
  }
}
