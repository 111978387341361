<!--INICIO SECCION CONTACTO-->
<section class="container mt-0" id="feedback">
    <div class="general-title-separation">
        <h1 class="text-center title mb-3">{{ 'feedback.title' | translate }}</h1>
        <div class="underline"></div>
    </div>
    <div class="border-container">
        <div class="subtitle">
            <p class="text-center mb-4 pb-4">{{ 'feedback.description' | translate }}</p>

            <!--FORMULARIO-->
            <div class="grid d-flex justify-content-center">
                <form [formGroup]="feedbackForm" (ngSubmit)="sendEmail()" id="contact-form" action="#" method="POST">
                    <div class="row">
                        <div class="col-12">
                            <input type="text" id="name" autocomplete="off" class="form-control" formControlName="name" placeholder="{{'feedback.pl_name' | translate}}">
                        </div>
                        <div class="col-12 pt-2">
                            <input type="text" id="subject" autocomplete="off" class="form-control" formControlName="subject" placeholder="{{'feedback.pl_subject' | translate}}">
                        </div>
                        <div class="col-12  pt-2">
                            <select id="feedback_type" class="form-select" formControlName="feedback_type" aria-label="Select problem">
                                <option value="" disabled selected>{{'feedback.type' | translate }}</option>
                                <option value="error">{{'feedback.error' | translate }}</option>
                                <option value="translate">{{'feedback.translate' | translate }}</option>
                                <option value="function">{{'feedback.function' | translate }}</option>
                                <option value="responsive">{{'feedback.responsive' | translate }}</option>
                                <option value="new">{{'feedback.new' | translate }}</option>
                                <option value="other_problem">{{'feedback.other_problem' | translate }}</option>
                            </select>
                        </div>

                        <div class="col-12">
                            <label class="form-label" for="customFile">{{'feedback.evidence' | translate }}</label>
                            <input style="margin-top: 0px !important; line-height: 3rem;" type="file"
                                (change)="chooseFile($event)" formControlName="evidence" class="form-control"
                                id="customFile"/>
                        </div>
                        <div class="col-12  pt-2">
                            <select id="section" class="form-select" formControlName="section" aria-label="Select section">
                                <option value="" disabled selected>{{'feedback.section' | translate }}</option>
                                <option value="home">{{'feedback.home' | translate }}</option>{{'feedback.' | translate }}
                                <option value="about">{{'feedback.about' | translate }}</option>
                                <option value="services">{{'feedback.services' | translate }}</option>
                                <option value="corporate">{{'feedback.corporate' | translate }}</option>
                                <option value="wellness">{{'feedback.wellness' | translate }}</option>
                                <option value="romance">{{'feedback.romance' | translate }}</option>
                                <option value="leisure">{{'feedback.leisure' | translate }}</option>
                                <option value="destination">{{'feedback.destination' | translate }}</option>
                                <option value="places">{{'feedback.places' | translate }}</option>
                                <option value="events">{{'feedback.events' | translate }}</option>
                                <option value="food">{{'feedback.food' | translate }}</option>
                                <option value="accommodation">{{'feedback.accommodation' | translate }}</option>
                                <option value="travel">{{'feedback.travel' | translate }}</option>
                                <option value="hospitality">{{'feedback.hospitality' | translate }}</option>
                                <option value="meetings">{{'feedback.meetings' | translate }}</option>
                                <option value="dmc">{{'feedback.dmc' | translate }}</option>
                                <option value="sourvey">{{'feedback.sourvey' | translate }}</option>
                                <option value="legals">{{'feedback.legals' | translate }}</option>
                                <option value="blog">{{'feedback.blog' | translate }}</option>
                                <option value="faqs">{{'feedback.faqs' | translate }}</option>
                                <option value="shopcart">{{'feedback.shopcart' | translate }}</option>
                                <option value="admin">{{'feedback.admin' | translate }}</option>
                                <option value="other_section">{{'feedback.other_section' | translate }}</option>

                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <textarea id="textarea" autocomplete="off" class="form-control" formControlName="comments"
                                placeholder="{{'feedback.area' | translate }}"
                                rows="3"></textarea>
                        </div>
                    </div>
                    <div class="pb-4 captcha text-center">
                        {{ "captcha.one" | translate}}
                        <a href="https://policies.google.com/privacy">{{ "captcha.two" | translate}}</a> {{
                        "captcha.three" | translate}}
                        <a href="https://policies.google.com/terms">{{ "captcha.four" | translate}}</a> {{
                        "captcha.five" | translate}}
                    </div>
                    <div class="row text-center pb-4 mb-4">
                        <div class="col-12">
                            <input type="submit" class="btn btn-outline-dark btn-submit" value="{{'feedback.button' | translate }}" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION CONTACTO-->
<app-alert></app-alert>
