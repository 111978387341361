import { Component } from '@angular/core';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent {

   //constante para imagenes y nombres de locaciones
   locations = [
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Carlos Aramburo",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Alejandro Aramburo",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"José Luis Galicia",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Veronica Guzman",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Mimiya Gonzalez",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Maydeth Duran",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Victor Hernandez",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
    {
      imgSrc:"assets/images/avatar.jpeg",
      name:"Nombre Apellido",
      position: "CEO"
    },
  ]
  constructor() {

  }

}
