import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationComponent } from '../information/information.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ViewportScroller, CommonModule } from '@angular/common';

@Component({
  selector: 'app-esr',
  standalone: true,
  imports: [InformationComponent, TranslateModule, CommonModule],
  templateUrl: './esr.component.html',
  styleUrl: './esr.component.scss'
})
export class EsrComponent {

  esrPictures: any[] = [{}, {}, {}, {}, {}, {}, {}];

  esr: string = '';

  esrData: { title: string, id: string, subtitle: string, description: string, resume: string, images: any, picture: string; body: string, icon: string,};

  constructor(private _translateService: TranslateService, private route: ActivatedRoute, private scroller: ViewportScroller, private router: Router) {
    this.esr = this.route.snapshot.params.id;

    this._translateService.stream('esr-view.services').subscribe(data => {
      this.esrData = data.find((el: any) => el.id === this.esr);
      if (!this.esrData) {
        this.router.navigate(['not-found']);
      }
    });
  }

  scrollDown() {
    this.scroller.scrollToAnchor("firstSection");
  }



}
