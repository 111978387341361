import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {

  @Output() userAction = new EventEmitter<boolean>();
  modalRef?: BsModalRef;
  @ViewChild('cookieBanner') template: TemplateRef<any>;
  config = {
    animated: false,
    ignoreBackdropClick: true,
    class: 'modal-cookie-banner'
  };

  private readonly cookiesConstKey: string = 'cookies-policy';

  constructor(private modalService: BsModalService) { }

  ngAfterViewInit() {
    const showCookieBanner: boolean = (Boolean)(localStorage.getItem(this.cookiesConstKey));
    if (!showCookieBanner) {
      this.modalRef = this.modalService.show(this.template, this.config);
    }
  }

  acceptedUserBy(option: boolean) {
    if (option) {
      localStorage.setItem(this.cookiesConstKey, 'true');
      this.modalRef?.hide();
    }
  }

}
