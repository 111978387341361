<!-- Feature Start -->
<div class="container-xxl section py-5">
    <div class="container">
        <div class="row g-5">
            <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                <div class="d-flex align-items-center mb-4">
                    <div class="btn-lg-square bg-primary rounded-circle me-3">
                        <i class="fa fa-users text-white"></i>
                    </div>
                    <h1 class="mb-0" data-toggle="counter-up">3453</h1>
                </div>
                <h5 class="mb-3 title title">Happy Customers</h5>
                <span>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span>
            </div>
            <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                <div class="d-flex align-items-center mb-4">
                    <div class="btn-lg-square bg-primary rounded-circle me-3">
                        <i class="fa fa-check text-white"></i>
                    </div>
                    <h1 class="mb-0" data-toggle="counter-up">4234</h1>
                </div>
                <h5 class="mb-3 title">Project Done</h5>
                <span>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span>
            </div>
            <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                <div class="d-flex align-items-center mb-4">
                    <div class="btn-lg-square bg-primary rounded-circle me-3">
                        <i class="fa fa-award text-white"></i>
                    </div>
                    <h1 class="mb-0" data-toggle="counter-up">3123</h1>
                </div>
                <h5 class="mb-3 title">Awards Win</h5>
                <span>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span>
            </div>
            <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                <div class="d-flex align-items-center mb-4">
                    <div class="btn-lg-square bg-primary rounded-circle me-3">
                        <i class="fa fa-users-cog text-white"></i>
                    </div>
                    <h1 class="mb-0" data-toggle="counter-up">1831</h1>
                </div>
                <h5 class="mb-3 title">Expert Workers</h5>
                <span>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span>
            </div>
        </div>
    </div>
</div>
<!-- Feature Start -->


<!-- About Start -->
<div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div class="container about px-lg-0">
        <div class="row g-0 mx-lg-0">
            <div class="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style="min-height: 400px;">
                <div class="position-relative h-100">
                    <img class="position-absolute img-fluid w-100 h-100" src="/assets/images/carousel-1.jpg" style="object-fit: cover;"
                        alt="">
                </div>
            </div>
            <div class="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                <div class="p-lg-5 pe-lg-0">
                    <h2 class="text-primary">About Us</h2>
                    <h1 class="mb-4">25+ Years Experience In Solar & Renewable Energy Industry</h1>
                    <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita
                        erat ipsum et lorem et sit, sed stet lorem sit clita duo justo erat amet</p>
                    <p><i class="fa fa-check-circle text-primary me-3"></i>Diam dolor diam ipsum</p>
                    <p><i class="fa fa-check-circle text-primary me-3"></i>Aliqu diam amet diam et eos</p>
                    <p><i class="fa fa-check-circle text-primary me-3"></i>Tempor erat elitr rebum at clita</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About End -->


<!-- Service Start -->
<div class="container-xxl">
    <div class="container">
        <div class="text-center mx-auto mb-5" data-wow-delay="0.1s" style="max-width: 600px;">
            <h2 class="text-primary">Our Services</h2>
            <h1 class="mb-4">We Are Pioneers In The World Of Renewable Energy</h1>
        </div>
        <div class="container px-5 mt-5 pt-5">
            <div class="row">

                <div class="col-12 col-lg-4 mb-5 mt-5 d-flex flex-column flex-md-row justify-content-center align-items-center" *ngFor="let ambiental of ambientalServices; index as i">
                    <div class="" data-wow-delay="0.1s">
                        <div class="service-item  rounded overflow-hidden">
                            <img class="img-fluid" [src]="ambiental.picture" alt="">
                            <div class="position-relative p-4 pt-0">
                                <div class="service-icon">
                                    <p class="" innerHTML="{{ambiental.icon}}"></p>
                                </div>
                                <h4 class="mb-3 title">{{ ambiental.title}}</h4>
                                <p>{{ ambiental.body}}</p>
                                
                                <a class="small fw-medium"[routerLink]="'/environment/'+ambiental.id">{{ 'esr.button' | translate }}<i class="fa fa-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        
    </div>



    </div>
</div>

<!-- Service End -->


<!-- Feature Start -->
<div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div class="container feature px-lg-0">
        <div class="row g-0 mx-lg-0">
            <div class="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                <div class="p-lg-5 ps-lg-0">
                    <h2 class="text-primary">Why Choose Us!</h2>
                    <h1 class="mb-4">Complete Commercial & Residential Solar Systems</h1>
                    <p class="mb-4 pb-2">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet
                        diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo erat amet</p>
                    <div class="row g-4">
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <div class="btn-lg-square bg-primary rounded-circle">
                                    <i class="fa fa-check text-white"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-0">Quality</p>
                                    <h5 class="mb-0">Services</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <div class="btn-lg-square bg-primary rounded-circle">
                                    <i class="fa fa-user-check text-white"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-0">Expert</p>
                                    <h5 class="mb-0">Workers</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <div class="btn-lg-square bg-primary rounded-circle">
                                    <i class="fa fa-drafting-compass text-white"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-0">Free</p>
                                    <h5 class="mb-0">Consultation</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <div class="btn-lg-square bg-primary rounded-circle">
                                    <i class="fa fa-headphones text-white"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-0">Customer</p>
                                    <h5 class="mb-0">Support</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style="min-height: 400px;">
                <div class="position-relative h-100">
                    <img class="position-absolute img-fluid w-100 h-100" src="assets/images/carousel-1.jpg"
                        style="object-fit: cover;" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Feature End -->




<!-- ANGULAR PROJECTS START-->

<div class="container">
    <div class="text-center mb-5">
      <h2 class="text-primary">Our Projects</h2>
      <h1>Visit Our Latest Solar And Renewable Energy Projects</h1>
    </div>
    <div class="text-center mb-5">
      <button class="btn " (click)="filtrarProyectos('all')">All</button>
      <button class="btn " (click)="filtrarProyectos('Solar Panels')">Solar Panels</button>
      <button class="btn " (click)="filtrarProyectos('Wind Turbines')">Wind Turbines</button>
      <button class="btn " (click)="filtrarProyectos('Hydropower Plants')">Hydropower Plants</button>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let proyecto of proyectosFiltrados">
        <div class="portfolio-img rounded overflow-hidden">
          <img class="img-fluid" [src]="proyecto.imagenUrl" alt="Project Image">
          <div class="portfolio-btn">
            <a class="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="#" data-lightbox="portfolio">
              <i class="fa fa-eye"></i>
            </a>
            <a class="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="#"><i class="fa fa-link"></i></a>
          </div>
        </div>
        <div class="pt-3">
          <p class="text-primary mb-0">{{ proyecto.categoria }}</p>
          <hr class="text-primary w-25 my-2">
          <h5 class="lh-base">{{ proyecto.nombre }}</h5>
        </div>
      </div>
    </div>
  </div>

<!-- END ANGULAR PROJECTS-->




<!-- Quote Start -->
<div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div class="container quote px-lg-0">
        <div class="row g-0 mx-lg-0">
            <div class="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style="min-height: 400px;">
                <div class="position-relative h-100">
                    <img class="position-absolute img-fluid w-100 h-100" src="assets/images/carousel-1.jpg" style="object-fit: cover;"
                        alt="">
                </div>
            </div>
            <div class="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
                <div class="p-lg-5 pe-lg-0">
                    <h2 class="text-primary">Free Quote</h2>
                    <h1 class="mb-4">Get A Free Quote</h1>
                    <p class="mb-4 pb-2">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet
                        diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo erat amet</p>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Quote End -->


<!-- Team Start -->
<div class="container-xxl py-5 final">
    <div class="container">
        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
            <h2 class="text-primary">AMI DMC <strong class="text">2030</strong></h2>
            <h1 class="mb-4">Actions to take</h1>
        </div>
        <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="team-item rounded overflow-hidden">
                    <div class="d-flex">
                        <img class="img-fluid w-75" src="assets/images/carousel-1.jpg" alt="">
                        <div class="team-social w-25">
                    
                            <a class="btn btn-lg-square btn-outline-primary rounded-circle mt-3" href=""><i
                                    class="fab fa-twitter"></i></a>
                            
                        </div>
                    </div>
                    <div class="p-4">
                        <h5>AMI 100% Digital</h5>
                        <span>Haciendo la transición a 100% digital, procesos internos y externos sin papel. ¡Adiós a las impresiones!</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-item rounded overflow-hidden">
                    <div class="d-flex">
                        <img class="img-fluid w-75" src="assets/images/carousel-1.jpg" alt="">
                        <div class="team-social w-25">
                            <a class="btn btn-lg-square btn-outline-primary rounded-circle mt-3" href=""><i
                                    class="fab fa-facebook-f"></i></a>
                            
                        </div>
                    </div>
                    <div class="p-4">
                        <h5>Distintivo ESR</h5>
                        <span>Proceso verificado por el Centro Mexicano para la Filantropía (CEMEFI).</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="team-item rounded overflow-hidden">
                    <div class="d-flex">
                        <img class="img-fluid w-75" src="assets/images/carousel-1.jpg" alt="">
                        <div class="team-social w-25">
                            <a class="btn btn-lg-square btn-outline-primary rounded-circle mt-3" href="">
                                <i class="fab fa-facebook-f"></i></a>
                           
                        </div>
                    </div>
                    <div class="p-4">
                        <h5>Buzón de quejas</h5>
                        <span>Buzón de quejas y denuncias para las/los trabajadores. Ambiente de trabajo 100% seguro.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Team End -->