import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { RecaptchaService } from '../../services/recaptcha/recaptcha.service';
import { EmailService } from '../../services/email/email.service';
import { AlertService } from '../../services/alert/alert.service';
import { environment } from '../../../environments/environment';

declare const mapkit: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  captchaAction: string = 'AMI_email_form';
  map: any;
  contactForm: FormGroup = this.formBuilder.group({});
  openAlert: boolean = false;
  contactMessages: any;
  translateSub: any;

  locations = [
    {
      imgSrc: 'assets/images/sanlucas.jpg',
      name: 'Cabo San Lucas',
      link: 'places/csl',
    },
    {
      imgSrc: 'assets/images/sjd.jpg',
      name: 'San José del Cabo',
      link: 'places/sjd',
    },
    {
      imgSrc: 'assets/images/santos.jpg',
      name: 'Todos Santos',
      link: 'places/todossantos',
    },
    {
      imgSrc: 'assets/images/lapaz.jpg',
      name: 'La Paz',
      link: 'places/paz',
    },
    {
      imgSrc: 'assets/images/cerritos.jpg',
      name: 'Cerritos',
      link: 'places/cerritos',
    },
    {
      imgSrc: 'assets/images/cabopulmo.jpeg',
      name: 'Cabo Pulmo',
      link: 'places/cabopulmo',
    },
    {
      imgSrc: 'assets/images/eltriunfo.jpg',
      name: 'El Triunfo',
      link: 'places/triunfo',
    },
    {
      imgSrc: 'assets/images/barriles.jpg',
      name: 'Los Barriles',
      link: 'places/barriles',
    },
  ];
  map2: any;
  landmarkSub: any;
  losCabosLandmarks: any;
  markers: any;

  constructor(
    private scroller: ViewportScroller,
    private _recaptchaService: RecaptchaService,
    private formBuilder: FormBuilder,
    private _emailService: EmailService,
    private _alertService: AlertService,
    private translate: TranslateService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.translateSub = this.translate
      .stream('messages')
      .subscribe((data: any) => {
        this.contactMessages = data;
      });

    this.landmarkSub = this.translate
      .stream('losCabosLandmarks')
      .subscribe((data: any) => {
        this.losCabosLandmarks = data;
        if (this.markers) {
          this.setMarkers();
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const header: number =
        document.getElementById('header')?.offsetHeight || 0;
      this.scroller.setOffset([0, header]);
    }, 1000);

    setTimeout(() => {
      this.initMap();
    }, 2000);
  }

  async initMap() {
    mapkit.init({
      authorizationCallback: function (done: any) {
        done(environment.mapToken);
      },
    });

    const AMI_COORDS = { lat: 22.887572, lng: -109.943608 };
    const sfo = new mapkit.Coordinate(AMI_COORDS.lat, AMI_COORDS.lng);
    const officeRegion = new mapkit.CoordinateRegion(
      new mapkit.Coordinate(AMI_COORDS.lat, AMI_COORDS.lng),
      new mapkit.CoordinateSpan(0.003, 0.006)
    );
    const BCRegion = new mapkit.CoordinateRegion(
      new mapkit.Coordinate(23.789645, -110.46981),
      new mapkit.CoordinateSpan(2, 3)
    );

    var contactMarker = new mapkit.MarkerAnnotation(sfo, {
      color: '#eff8fb',
      title: 'AMI® DMC Los Cabos',
      glyphText: '\u{1F335}',
    });

    const map = new mapkit.Map('map');
    map.region = officeRegion;
    map.showItems([contactMarker]);

    this.map2 = new mapkit.Map('map-interactive');
    this.map2.region = BCRegion;
    this.setMarkers();
  }

  scrollDown() {
    this.scroller.scrollToAnchor('firstSection');
  }

  setMarkers() {
    if (this.markers) {
      this.map2.removeAnnotations(Array.from(this.markers.keys()));
    }
    this.markers = new Map();
    const landmarkAnnotationCallout = {
      calloutElementForAnnotation: (annotation: any) => {
        const landmark = this.markers.get(annotation);
        const div = document.createElement('div');
        div.className = 'pin-overlay';
        const title = div.appendChild(document.createElement('h1'));
        title.textContent = landmark.title;
        const section = div.appendChild(document.createElement('section'));
        const place = section.appendChild(document.createElement('p'));
        place.textContent = landmark.place;
        const description = section.appendChild(document.createElement('p'));
        description.textContent = landmark.description;
        const redirect = section.appendChild(document.createElement('a'));
        redirect.textContent = landmark.detail;
        redirect.href = landmark.linkToPlaces;
        return div;
      },
      calloutAnchorOffsetForAnnotation: (annotation: any, element: any) =>
        new DOMPoint(-148, -78),
      calloutAppearanceAnimationForAnnotation: (annotation: any) =>
        '.4s cubic-bezier(0.4, 0, 0, 1.5) ' + '0s 1 normal scale-and-fadein',
    };
    for (let pin of this.losCabosLandmarks) {
      const marker = new mapkit.MarkerAnnotation(
        new mapkit.Coordinate(pin.lat, pin.lng),
        {
          color: '#EDD000',
          title: pin.title,
          callout: landmarkAnnotationCallout,
        }
      );
      this.markers.set(marker, pin);
    }
    this.map2.showItems(Array.from(this.markers.keys()));
  }

  initForm(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'),
        ],
      ],
      message: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  checkInvalidFields(): void {
    if (this.contactForm.invalid) {
      Object.values(this.contactForm.controls).map((control) => {
        control.markAsTouched();
      });
    }
  }

  get isNameValid(): boolean | undefined {
    return (
      this.contactForm.get('name')?.invalid &&
      this.contactForm.get('name')?.touched
    );
  }

  get isEmailValid(): boolean | undefined {
    return (
      this.contactForm.get('email')?.invalid &&
      this.contactForm.get('email')?.touched
    );
  }

  get isMessageValid(): boolean | undefined {
    return (
      this.contactForm.get('message')?.invalid &&
      this.contactForm.get('message')?.touched
    );
  }

  async sendEmail() {
    try {
      if (this.contactForm.invalid) {
        return this.checkInvalidFields();
      }

      const recaptcha: string = await this._recaptchaService.executeAction(
        this.captchaAction
      );

      const emailData: any = {
        name: this.contactForm.get('name')?.value,
        email: this.contactForm.get('email')?.value,
        message: this.contactForm.get('message')?.value,
        process: 'contactForm',
        recaptchaToken: recaptcha,
        recaptchaAction: this.captchaAction,
      };

      const emailContactSub = this._emailService
        .sendEmail(emailData)
        .subscribe({
          next: (data) => {
            emailContactSub.unsubscribe();
            if (data.status === 'success') {
              this.contactForm.reset();
              this._alertService.showAlert.next({
                isOpen: true,
                type: 'success',
                title: this.contactMessages.contact.send,
                message: this.contactMessages.contact.success,
              });
            } else {
              this._alertService.showAlert.next({
                isOpen: true,
                type: 'error',
                title: this.contactMessages.error,
                message: this.contactMessages.contact.error,
              });
            }
          },
          error: (error) => {
            emailContactSub.unsubscribe();
            this._alertService.showAlert.next({
              isOpen: true,
              type: 'error',
              title: this.contactMessages.error,
              message: this.contactMessages.contact.ups,
            });
          },
        });
    } catch (e) {
      this._alertService.showAlert.next({
        isOpen: true,
        type: 'error',
        title: this.contactMessages.error,
        message: this.contactMessages.contact.ups,
      });
    }
  }

  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
    if (this.landmarkSub) {
      this.landmarkSub.unsubscribe();
    }
  }
}
