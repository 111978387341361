<!--INICIO HERO-->
<section class="hero-section">
    <div class="hero-container">
        <div class="found-container">
            <h1> {{ "home.hero" | translate }} <br /> {{ "home.hero1" | translate }} </h1>
        </div>
        <div class="logo-container">
            <img src="assets/images/AMI_wh_br.png" alt="logo" />
        </div>
        <div class="description-container">
            <h1>{{ "home.hero2" | translate }}</h1>
            <h5>{{ "home.hero3" | translate }}</h5>
        </div>
        <div class="scroll">
            <p>
                <span (click)="scrollDown()">
                    <i class="fa-solid fa-circle-arrow-down"></i>
                    {{ 'buttons.scroll' | translate }}
                </span>
            </p>
        </div>
    </div>
</section>
<!--FIN HERO-->

<!--INICIO SECCION LOS CABOS-->
<section id="firstSection" class="bg-blue">
    <div class="container desc-container">
        <div class="left">
            <h1>{{'destination.title' | translate}}</h1>
            <p>{{'destination.description' | translate}}</p>
        </div>
        <div class="right">
            <img src="assets/images/article1.jpg" alt="Los cabos" />
        </div>
    </div>
</section>
<!--FIN SECCION LOS CABOS-->

<!--INICIO SECCION MAPA INTERACTIVO-->
<section>
    <div class="container map-interactive-container pt-0">
        <div class="title-section general-title-separation">
            <h1 class="text-center ">{{'destination.interactive_map' | translate}}</h1>
            <div class="underline"></div>
        </div>
        <div class="map-interactive" id="map-interactive"></div>
    </div>
</section>
<!--FIN SECCION MAPA INTERACTIVO-->

<!--INICIO SECCION LUGARES-->
<section class="bg-blue">
    <div class="thirdSection text-center">
        <div class="general-title-separation mt-0 pt-100">
            <p class="title-section">
                {{'destination.locations_title' | translate}}
            </p>
            <div class="underline"></div>
        </div>
        <div class="container px-5">
            <div class="row">
                <div class="col-12 col-md-3 location" *ngFor="let location of locations">
                    <img [src]="location.imgSrc" class="location-img" alt="" [routerLink]="location.link">
                    <p class="location-txt">{{location.name}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION LUGARES-->

<!--INICIO SECCION VISIT-->
<section class="visit-container">
    <div class="text-center">
        <h1>{{ 'home.visit' | translate }}</h1>
        <p>{{ 'home.visit1' | translate }} </p>
        <input type="button" class="btn btn-outline-dark bot" routerLink="/destination"
            value="{{ 'buttons.read' | translate }}" />
    </div>
</section>
<!--FIN SECCION VISIT-->

<!--INICIO SECCION RESTAURANTES-->
<section>
    <div class="container restaurants-container py-0">
        <div class="title-section general-title-separation">
            <h1 class="text-center ">{{ 'home.rest' | translate }}</h1>
            <div class="underline"></div>
        </div>
        <div class="grid">
            <div class="description mt-0">
                <p>{{ 'home.rest_desc' | translate }}</p>
            </div>
            <div class="left">
                <img src="assets/images/pub.jpeg" alt="">
                <h1>{{ 'home.rest_1' | translate }}</h1>
            </div>
            <div class="right">
                <img src="assets/images/coffee.jpeg" alt="">
                <h1>{{ 'home.rest_2' | translate }}</h1>
            </div>
            <div class="action">
                <input type="button" class="btn btn-outline-dark" routerLink="/f&d"
                    value="{{ 'buttons.see' | translate }}" />
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION RESTAURANTES-->

<!--INICIO SECCION HOTELES-->
<section>
    <div class="container hotels-container py-0">
        <div class="title-section general-title-separation">
            <h1 class="text-center">{{ 'home.hotel' | translate }}</h1>
            <div class="underline"></div>
        </div>
        <div class="grid">
            <div class="description">
                <p>{{ 'home.hotel_desc' | translate }}</p>
            </div>
            <div class="left">
                <img src="assets/images/resort.jpeg" alt="">
                <h4>{{ 'home.hotel_1' | translate }}</h4>
                <p>Price</p>
            </div>
            <div class="right">
                <img src="assets/images/luxury1.jpg" alt="">
                <h4>{{ 'home.hotel_2' | translate }}</h4>
                <p>Price</p>
            </div>
            <div class="left-bottom">
                <img src="assets/images/all.jpeg" alt="">
                <h4>{{ 'home.hotel_3' | translate }}</h4>
                <p>Price</p>
            </div>
            <div class="middle-bottom">
                <img src="assets/images/golfresort.jpeg" alt="">
                <h4>{{ 'home.hotel_4' | translate }}</h4>
                <p>Price</p>
            </div>
            <div class="right-bottom">
                <img src="assets/images/hotelbeach.jpeg" alt="">
                <h4>{{ 'home.hotel_5' | translate }}</h4>
                <p>Price</p>
            </div>
            <div class="action">
                <input type="button" class="btn btn-outline-dark" routerLink="/accommodation"
                    value="{{ 'buttons.see' | translate }}" />
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION HOTELES-->

<!--INICIO SECCION EVENTOS-->
<section>
    <div class="container events-container pt-0">
        <div class="title-section general-title-separation">
            <h1 class="text-center mb-3">{{ 'home.event_title' | translate }}</h1>
            <div class="underline"></div>
        </div>
        <div class="grid">
            <div class="description mt-0">
                <p>{{ 'home.event_title_desc' | translate }}</p>
            </div>
            <div class="left">
                <img src="assets/images/food.jpeg" alt="">
                <h4>{{ 'home.event_date_1' | translate }}</h4>
                <h1>{{ 'home.event_title_1' | translate }}</h1>
                <p>{{ 'home.event_desc_1' | translate }}</p>
            </div>
            <div class="middle">
                <img src="assets/images/event.jpeg" alt="">
                <h4>{{ 'home.event_date_2' | translate }}</h4>
                <h1>{{ 'home.event_title_2' | translate }}</h1>
                <p>{{ 'home.event_desc_2' | translate }}</p>
            </div>
            <div class="right">
                <img src="assets/images/fishsport.jpeg" alt="">
                <h4>{{ 'home.event_date_3' | translate }}</h4>
                <h1>{{ 'home.event_title_3' | translate }}</h1>
                <p>{{ 'home.event_desc_3' | translate }}</p>
            </div>
            <div class="action">
                <input type="button" class="btn btn-outline-dark" routerLink="/events"
                    value="{{ 'buttons.see' | translate }}" />
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION EVENTOS-->

<!--INICIO SECCION LUGARES-->
<section id="places" class="bg-blue">
    <div class="container values-container pt-0 ">
        <div class="title-section general-title-separation pt-100">
            <h1 class="text-center mb-6">{{ 'home.places.place_title' | translate }}</h1>
            <div class="underline"></div>

        </div>
        <p class="subtitle">{{ 'home.places.place_desc' | translate }}</p>
        <div class="grid">
            <div class="item location" routerLink="/destination" fragment="beaches">
                <div class="icon ">
                    <i class="fa-solid fa-location-arrow"></i>
                </div>
                <h1>{{ 'home.places.place1' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="todo">
                <div class="icon">
                    <i class="fa-solid fa-anchor"></i>
                </div>
                <h1>{{ 'home.places.place2' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="places">
                <div class="icon">
                    <i class="fa-solid fa-road"></i>
                </div>
                <h1>{{ 'home.places.place3' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="conectivity">
                <div class="icon">
                    <i class="fa-solid fa-map"></i>
                </div>
                <h1>{{ 'home.places.place4' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="airports">
                <div class="icon">
                    <i class="fa-solid fa-map-pin"></i>
                </div>
                <h1>{{ 'home.places.place5' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="activities">
                <div class="icon">
                    <i class="fa-solid fa-map-marker"></i>
                </div>
                <h1>{{ 'home.places.place6' | translate }}</h1>
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION LUGARES-->

<!--INICIO SECCION PLAYAS-->
<section>
    <div class="container beachs-container">
        <div class="left">
            <h1>{{ 'home.beach_title' | translate }}</h1>
            <p>{{ 'home.beach_desc' | translate }}</p>
            <div class="footer-action">
                <input type="button" class="btn btn-outline-dark bot-bck" routerLink="/destination" fragment="beaches"
                    value="{{ 'buttons.see' | translate }}" />
            </div>
        </div>
        <div class="right">
            <div>
                <img src="assets/images/beach1.jpg" alt="" />
            </div>
            <div>
                <img src="assets/images/beach2.jpeg" alt="" />
            </div>
            <div>
                <img src="assets/images/beach3.jpg" alt="" />
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION PLAYAS-->

<!--INICIO SECCION EL ARCO-->
<section>
    <div class="container arch-container">
        <div class="left">
            <div>
                <img src="assets/images/arco4.jpeg" alt="" />
            </div>
            <div>
                <img src="assets/images/arco.jpg" alt="" />
            </div>
            <div>
                <img src="assets/images/arco3.jpeg" alt="" />
            </div>
        </div>
        <div class="right">
            <h1>{{ 'home.arch_title' | translate }}</h1>
            <p>{{ 'home.arch_desc' | translate }}</p>
            <div class="footer-action">
                <input type="button" class="btn btn-outline-dark bot-bck" routerLink="/places/arco"
                    value="{{ 'buttons.see' | translate }}" />
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION EL ARCO-->

<!--INICIO SECCION NEWS-->
<section class="bg-blue ">
    <div class="news-container container pt-0">

        <div class="title-section general-title-separation mt-0 pt-100">
            <h1 class="text-center mb-3">{{ 'news.title' | translate }}</h1>
            <div class="underline"></div>
        </div>

        <p class="sec-description">{{ 'news.subtitle' | translate }}</p>

        <div class="news-grid">
            <div class="news-item">
                <div class="news-body">
                    <p>{{ 'news.n2' | translate }}</p>
                </div>
                <div class="author">
                    <img src="assets/images/avatar.jpg" alt=""/>
                    <div class="right">
                        <h5>{{ 'news.n3' | translate }}</h5>
                        <p>{{ 'news.n4' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="news-item">
                <div class="news-body">
                    <p>{{ 'news.n5' | translate }}</p>
                </div>
                <div class="author">
                    <img src="assets/images/avatar.jpg" alt=""/>
                    <div class="right">
                        <h5>{{ 'news.n7' | translate }}</h5>
                        <p>{{ 'news.n8' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="news-item">
                <div class="news-body">
                    <p>{{ 'news.n9' | translate }}</p>
                </div>
                <div class="author">
                    <img src="assets/images/avatar.jpg" alt=""/>
                    <div class="right">
                        <h5>{{ 'news.n11' | translate }}</h5>
                        <p>{{ 'news.n12' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-action">
            <input type="button" class="btn btn-outline-dark bot-bck" routerLink="/blog"
                value="{{ 'buttons.see' | translate }}" />
        </div>
    </div>
</section>
<!--FIN SECCION NEWS-->

<!--INICIO SECCION CONTACTO-->
<section class="contact-container mt-0" id="contact">
    <div class="title-section general-title-separation">
        <h1 class="text-center mb-3">{{ 'contact.title' | translate }}</h1>
        <div class="underline"></div>
    </div>
    <div class="border-container">
        <div class="left">
            <h1>{{ 'contact.subtitle' | translate }}</h1>
            <div class="location">
                <i class="fa-solid fa-location-dot fs-4"></i>
                <p class="contact-info">{{ "contact.address" | translate }}</p>
            </div>
            <div class="phone">
                <i class="fa-solid fa-phone fs-4"></i>
                <p class="contact-info"><a href="tel:+526241307060">{{ "contact.phone" | translate }}</a></p>
            </div>
            <div class="address">
                <i class="fa-solid fa-mobile fs-4"></i>
                <p class="contact-info"><a class=""
                        href="https://wa.me/+526241586071?text=Hi!%20I%20need%20info%20about%20AMI%20DMC%20Los%20Cabos."
                        target="_blank">{{ "contact.whats" | translate }}</a></p>
            </div>
            <div class="address">
                <i class="fa-solid fa-envelope fs-4"></i>
                <p class="contact-info"><a href="mailto:info@amidmcloscabos.com?Subject=Info%20about%20AMI%20DMC">{{
                        "contact.mail" | translate }}</a></p>
            </div>
            <!--FORMULARIO-->
            <div class="grid">
                <form [formGroup]="contactForm" (ngSubmit)="sendEmail()" id="contact-form" action="#" method="POST">
                    <div class="row">
                        <div class="col-6">
                            <input type="text" class="form-control" formControlName="name" id="name" autocomplete="off"
                                [class.is-inpt-invalid]="isNameValid" placeholder="{{ 'contact.name' | translate }}">
                        </div>
                        <div class="col-6">
                            <input type="email" class="form-control" formControlName="email" id="email" autocomplete="off"
                                [class.is-inpt-invalid]="isEmailValid" placeholder="{{ 'contact.email' | translate }}">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <textarea class="form-control" formControlName="message" id="message" autocomplete="off"
                                [class.is-inpt-invalid]="isMessageValid" placeholder="{{ 'contact.text' | translate }}"
                                rows="3"></textarea>
                        </div>
                    </div>
                    <div class="pb-4 captcha text-center">
                        {{ "captcha.one" | translate}}
                        <a href="https://policies.google.com/privacy">{{ "captcha.two" | translate}}</a> {{
                        "captcha.three" | translate}}
                        <a href="https://policies.google.com/terms">{{ "captcha.four" | translate}}</a> {{
                        "captcha.five" | translate}}
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <input type="submit" class="btn btn-outline-dark btn-submit"
                                value="{{ 'buttons.send' | translate }}" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!--MAPA-->
        <div class="right">
            <div class="map" id="map"></div>
        </div>
    </div>
</section>

<!--FIN SECCION CONTACTO-->

<!--MODAL-->

<app-alert></app-alert>

<!--BANNER COOKIES-->

<app-cookies></app-cookies>
