<section class="bg-blue">
    <div class="container action-container">
        <div>
            <h1>{{ 'nedd_information.title' | translate }}</h1>
        </div>
        <div class="btn-container">
            <input type="button" class="btn btn-outline-dark btn-submit" (click)="openModal(template)"
                value="{{ 'nedd_information.button' | translate }}" />
        </div>
    </div>
</section>

<ng-template #template>
    <div class="modal-header">
        <button type="button" class="btn-close close pull-right" style="cursor: pointer;" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center  px-5">
        <h4 class="modal-title pull-left mb-4">{{'modal_more_info.title' | translate}}</h4>
        <p class="description mb-5">{{'modal_more_info.descrpition' | translate}}</p>
        <form action="" [formGroup]="infoForm" (ngSubmit)="onSubmit()">
            <div class="row m-0">
                <div class="col-12 col-md-4 text-start">
                    <strong class="label-strong"> {{'modal_more_info.name' | translate}}<span
                            class="text-red">*</span></strong>
                    <input type="text" formControlName="name" [class.is-inpt-invalid]="isNameValid"
                        class="general-input w-100 mt-3" placeholder=" {{ 'modal_more_info.name' | translate }}">
                </div>
                <div class="col-12 col-md-4 text-start">
                    <strong class="label-strong"> {{'modal_more_info.phone' | translate}}</strong>
                    <input type="text" formControlName="phone" [class.is-inpt-invalid]="isPhoneValid"
                        class="general-input w-100 mt-3" placeholder=" {{ 'modal_more_info.phone' | translate }}">
                </div>
                <div class="col-12 col-md-4 text-start">
                    <strong class="label-strong"> {{'modal_more_info.mail' | translate}}<span
                            class="text-red">*</span></strong>
                    <input type="text" formControlName="email" [class.is-inpt-invalid]="isEmailValid"
                        class="general-input w-100 mt-3" placeholder=" {{ 'modal_more_info.mail' | translate }}">
                </div>
            </div>
            <div class="row m-0 text-start my-3">
                <div class="col-12">
                    <strong class="label-strong"> {{'modal_more_info.message' | translate}}<span
                            class="text-red">*</span></strong>
                    <textarea type="text" formControlName="message" [class.is-inpt-invalid]="isMessageValid"
                        class="general-input h-auto w-100 mb-0 mt-3"
                        placeholder="{{ 'modal_more_info.place_message' | translate }}" cols="30" rows="4"></textarea>
                    <span class="text-red">*{{'modal_more_info.required' | translate}}</span>
                </div>
            </div>
            <div class="pb-4 captcha text-center">
                {{ "captcha.one" | translate}}
                <a href="https://policies.google.com/privacy">{{ "captcha.two" | translate}}</a> {{
                "captcha.three" | translate}}
                <a href="https://policies.google.com/terms">{{ "captcha.four" | translate}}</a> {{
                "captcha.five" | translate}}
            </div>
            <div class="row m-0 justify-content-center mb-5">
                <button type="submit" class="btn btn-outline-dark btn-custom-2 me-5" aria-label="Close"
                    (click)="modalRef?.hide()">{{ 'modal_more_info.cancel_btn' | translate }}</button>
                <button type="submit" [disabled]="infoForm.invalid" class="btn btn-outline-dark btn-custom-2">{{
                    'modal_more_info.send_btn' | translate }}</button>
            </div>
        </form>
    </div>
</ng-template>