import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback.component';
import { AlertModule } from '../../components/alert/alert.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    AlertModule
  ],
  exports: [FeedbackComponent]
})
export class FeedbackModule { }
