import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare const grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  myScriptElement: HTMLScriptElement = document.createElement('script');

  constructor() {
    this.init();
  }

  init() {
    this.myScriptElement.defer = true;
    this.myScriptElement.src = `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaKey}`;
    document.body.appendChild(this.myScriptElement);
  }

  executeAction(action: string): Promise<string> {
    return grecaptcha.execute(environment.recaptchaKey, {
      action,
    });
  }
}
