import { Component } from '@angular/core';
import { ViewportScroller, CommonModule } from '@angular/common';
import { TranslateModule , TranslateService} from '@ngx-translate/core';
import { RouterModule, Router } from '@angular/router';

interface Proyecto {
  nombre: string;
  categoria: string;
  imagenUrl: string;
}

@Component({
  selector: 'app-ambiental',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
  templateUrl: './ambiental.component.html',
  styleUrl: './ambiental.component.scss'
})
export class AmbientalComponent {
  
  ambientalServices: { 
    title: string, 
    id: string, 
    subtitle: string, 
    description: string, 
    resume: string, 
    images: any, 
    picture: string; 
    body: string,
    icon: string,
  }[];

  proyectos: Proyecto[] = [];
  proyectosFiltrados: Proyecto[] = [];
  translateSub: any;

  constructor(private translate: TranslateService) {}


  async ngOnInit() {
    this.translateSub = this.translate.stream('proyectos.services').subscribe((data: any) => {
      this.proyectos = data;
      this.proyectosFiltrados = this.proyectos; 
    });
    this.translateSub = this.translate.stream('esr-view.services').subscribe((data: any) => {
      this.ambientalServices = data;
    });
  }

  filtrarProyectos(categoria: string) {
    if (categoria === 'all') {
      this.proyectosFiltrados = this.proyectos;
    } else {
      this.proyectosFiltrados = this.proyectos.filter(proyecto => proyecto.categoria === categoria);
    }
  }

  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }

}
