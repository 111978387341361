<ng-template #cookieBanner>
    <div class="modal-body text-center px-5">
        <div class="cookie-banner">
            <div class="container">
                <div class="title">
                    <h1>
                        <img src="assets/icons/cookie.svg" alt="cookie" />
                        Cookies
                    </h1>
                </div>
                <div class="grid">
                    <div>
                        <p>
                            {{ 'cookies.one' | translate }}
                            <strong class="fw-bold">{{ 'cookies.two' | translate }}</strong>
                        </p>
                        <p>
                            {{ 'cookies.three' | translate }}
                            <strong class="fw-bold">{{ 'cookies.four' | translate }}</strong>
                            {{ 'cookies.five' | translate }}
                        </p>
                        <p>
                            {{ 'cookies.six' | translate }}
                            <strong class="fw-bold">{{ 'cookies.seven' | translate }}</strong>
                            {{ 'cookies.eight' | translate }}
                        </p>
                    </div>
                    <div class="controls">
                        <input type="button" (click)="acceptedUserBy(true)" class="btn btn-outline-dark"
                            value="{{ 'cookies.btn_ok' | translate }}" />
                        <input type="button" (click)="acceptedUserBy(false)" class="btn btn-outline-dark"
                            value="{{ 'cookies.btn_cancel' | translate }}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>