import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { alertI } from 'src/app/interfaces/alert.interface';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  modalRef?: BsModalRef;

  @ViewChild('template') template: TemplateRef<any>;

  alertOpt: alertI;

  constructor(private modalService: BsModalService, private _alertService: AlertService) { }

  ngOnInit() {
    this._alertService.showAlert.subscribe(data => {
      this.alertOpt = data;
      this.toogleModal(this.alertOpt.isOpen);
    });
  }

  toogleModal(isOpen: boolean) {
    if (isOpen) {
      this.modalRef = this.modalService.show(this.template);
    }
    else {
      this.modalRef?.hide();
    }
  }

}
