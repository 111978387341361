<section>
    <div class="hero-container" [style.background-image]="'url(' + esrData.picture + ')'">
        <div class="grid">
            <h1>{{ esrData.title }}</h1>
            <p class="description">{{ esrData.description }}</p>
        </div>
        <div class="scroll-down d-flex justify-content-center">
            <p>
                <span (click)="scrollDown()">
                    <i class="fa-solid fa-circle-arrow-down"></i>
                    {{ 'buttons.scroll' | translate }}
                </span>
            </p>
        </div>
    </div>
</section>

<section id="firstSection">
    <div class="container">
        <div class="text-center ">
            <div class="">
                <h1 class="esr-title">{{ esrData.subtitle }}</h1>
                <p class="esr-content" [innerHTML]="esrData.resume"></p>
            </div>
           <!--<div class="service" *ngFor="let esr of esrData.images">
                <img [src]="esr.url" />
            </div>-->
        </div>
    </div>
</section>

<app-information></app-information>