import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from '../../components/alert/alert.module';
import { HomeComponent } from './home.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { CookiesComponent } from '../../components/cookies/cookies.component';



@NgModule({
  declarations: [HomeComponent, CookiesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AlertModule
  ],
  exports: [HomeComponent, CookiesComponent]
})
export class HomeModule { }
