import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { EsrComponent } from './pages/esr/esr.component';
import { AmbientalComponent } from './pages/ambiental/ambiental.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./pages/about/about.component').then((m) => m.AboutComponent),
  },
  {
    path: 'environment/:id',
    component: EsrComponent,
  },
  {
    path: 'ambiental',
    component: AmbientalComponent,
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./pages/services/services.module').then((m) => m.ServicesModule),
  },
  {
    path: 'romance',
    loadChildren: () =>
      import('./pages/romance/romance.module').then((m) => m.RomanceModule),
  },
  {
    path: 'wellness',
    loadChildren: () =>
      import('./pages/welfare/welfare.module').then((m) => m.WelfareModule),
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./pages/events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'places',
    loadChildren: () =>
      import('./pages/places/places.module').then((m) => m.PlacesModule),
  },
  {
    path: 'destination',
    loadChildren: () =>
      import('./pages/destination/destination.module').then(
        (m) => m.DestinationModule
      ),
  },
  {
    path: 'accommodation',
    loadChildren: () =>
      import('./pages/hotels/hotels.module').then((m) => m.HotelsModule),
  },
  {
    path: 'travel',
    loadChildren: () =>
      import('./pages/travel/travel.module').then((m) => m.TravelModule),
  },
  {
    path: 'leisure',
    loadChildren: () =>
      import('./pages/leisure/leisure.module').then((m) => m.LeisureModule),
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    /* loadComponent: () =>
      import('./pages/feedback/feedback.component').then((m) => m.FeedbackComponent), */
  },
  {
    path: 'f&d',
    loadChildren: () =>
      import('./pages/restaurants/restaurants.module').then(
        (m) => m.RestaurantsModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./pages/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'sourvey',
    loadComponent: () =>
      import('./pages/your-opinion/your-opinion.component').then(
        (m) => m.YourOpinionComponent
      ),
  },
  {
    path: 'faqs',
    loadComponent: () =>
      import('./pages/faqs/faqs.component').then((m) => m.FaqsComponent),
  },
  {
    path: 'dmc',
    loadComponent: () =>
      import('./pages/dmc/dmc.component').then((m) => m.DmcComponent),
  },
  {
    path: 'legal',
    loadComponent: () =>
      import('./pages/legals/legals.component').then((m) => m.LegalsComponent),
  },
  {
    path: 'policies',
    loadComponent: () =>
      import('./pages/policies/policies.component').then(
        (m) => m.PoliciesComponent
      ),
  },
  {
    path: 'incentives',
    loadComponent: () =>
      import('./pages/incentives/incentives.component').then(
        (m) => m.IncentivesComponent
      ),
  },
  {
    path: 'hospitality',
    loadComponent: () =>
      import('./pages/hospitality/hospitality.component').then(
        (m) => m.HospitalityComponent
      ),
  },
  {
    path: 'trip',
    loadComponent: () =>
      import('./pages/mytrip/mytrip.component').then((m) => m.MytripComponent),
  },
  {
    path: 'company',
    loadComponent: () =>
      import('./pages/company/company.component').then(
        (m) => m.CompanyComponent
      ),
  },
  {
    path: 'contracts',
    loadChildren: () =>
      import('./pages/contracts/contracts.module').then(
        (m) => m.ContractsModule
      ),
  },
  {
    path: 'not-found',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
