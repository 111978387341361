<div class="footer-container">
        <div class="sections">
                <div class="contact-info">
                        <h1>Agencia Mexicana Integradora</h1>
                        <p class="mar-btm-3">{{ "footer.name" | translate }}</p>
                        <p>{{ "footer.reg" | translate }} N<sup>o</sup> 27030080007</p>
                        <div class="icons">
                                <a href="https://facebook.com/amidmcloscabos" target="_blank" rel="noopener noreferrer">
                                        <span>
                                                <i class="fa-brands fa-facebook-f"></i>
                                        </span>
                                </a>
                                <a href="https://www.linkedin.com/company/ami-los-cabos/" target="_blank"
                                        rel="noopener noreferrer">
                                        <span>
                                                <i class="fa-brands fa-linkedin-in"></i>
                                        </span>
                                </a>
                                <a href="https://foursquare.com/v/ami-dmc-los-cabos/51ba0edc7dd2689d5a5cd724"
                                        target="_blank" rel="noopener noreferrer">
                                        <span>
                                                <i class="fa-brands fa-foursquare"></i>
                                        </span>
                                </a>
                                <a href="https://www.youtube.com/@amidmcloscabosagenciamexic7094" target="_blank"
                                        rel="noopener noreferrer">
                                        <span>
                                                <i class="fa-brands fa-youtube"></i>
                                        </span>
                                </a>
                                <a href="https://instagram.com/amidmcloscabos" target="_blank"
                                        rel="noopener noreferrer">
                                        <span>
                                                <i class="fa-brands fa-instagram"></i>
                                        </span>
                                </a>
                                <a href="https://x.com/amidmcloscabos" target="_blank" rel="noopener noreferrer">
                                        <span>
                                                <i class="fa-brands fa-x-twitter"></i>
                                        </span>
                                </a>
                                <a href="https://wa.me/+526241586071?text=Hi!%20I%20need%20info%20about%20AMI%20DMC%20Los%20Cabos."
                                        target="_blank" rel="noopener noreferrer">
                                        <span>
                                                <i class="fa-brands fa-whatsapp"></i>
                                        </span>
                                </a>
                        </div>
                        <p class="mar-btm-3">{{ "footer.address1" | translate }}</p>
                        <p class="mar-btm-1">{{ "footer.address2" | translate }}</p>
                        <p>
                                <a href="tel:+526241307060">{{ "footer.phone" | translate }}</a> |
                                <a href="tel:+526241438698">{{"footer.phone2" | translate }}</a> |
                                <a href="tel:+526241438699">{{ "footer.phone3" | translate }}</a> |
                                <a href="tel:+526241307854">{{ "footer.phone4" | translate }}</a>
                        </p>
                        <p>
                                <a href="tel:+18559264362">{{ "footer.phonefree" | translate }}</a>
                        </p>
                        <p class="mar-btm-3">
                                <a
                                        href="mailto:info@amidmcloscabos.com?Subject=Info%20about%20AMI%20DMC">info&#64;amidmcloscabos.com</a>
                        </p>
                </div>
                <div class="flex-items">
                        <h2>{{ "footer.1" | translate }}</h2>
                        <h5>
                                <a routerLink="/services" routerLinkActive="active">{{ "footer.2" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/ambiental" routerLinkActive="active">{{ "footer.26" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/hospitality" routerLinkActive="active">{{ "footer.3" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/dmc" routerLinkActive="active">{{ "footer.4" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/incentives" routerLinkActive="active">{{ "footer.24" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/wellness" routerLinkActive="active">{{ "footer.5" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/romance" routerLinkActive="active">{{ "footer.6" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/sourvey" routerLinkActive="active">{{ "footer.7" | translate }}</a>
                        </h5>
                </div>
                <div class="flex-items">
                        <h2>{{ "footer.8" | translate }}</h2>
                        <h5>
                                <a routerLink="/legal" [queryParams]="{policy: 'terms'}" routerLinkActive="active">{{
                                        "footer.11" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/legal" [queryParams]="{policy: 'privacy'}" routerLinkActive="active">{{
                                        "footer.9" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/legal" [queryParams]="{policy: 'cookies'}" routerLinkActive="active">{{
                                        "footer.10" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/legal" [queryParams]="{policy: 'ARCO'}" routerLinkActive="active">{{
                                        "footer.13" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/policies" [queryParams]="{policy: 'cancelation'}"
                                        routerLinkActive="active">{{ "footer.23" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/policies" [queryParams]="{policy: 'refund'}"
                                        routerLinkActive="active">{{ "footer.12" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/contracts" routerLinkActive="active">{{"footer.25" | translate }}</a>
                        </h5>
                </div>

                <div class="flex-items">
                        <h2>{{ "footer.14" | translate }}</h2>
                        <h5>
                                <a routerLink="/faqs" routerLinkActive="active">{{ "footer.19" | translate }}</a>
                        </h5>
                        <h5>
                                <a routerLink="/blog" routerLinkActive="active">{{ "footer.20" | translate }}</a>
                        </h5>
                        <h5>
                                <a href="https://visitmexico.com/" target="_blank" rel="noopener noreferrer">{{
                                        "footer.17" | translate }}</a>
                        </h5>

                        <h5>
                                <a href="https://amdemac.org/" target="_blank" rel="noopener noreferrer">{{ "footer.15"
                                        | translate}}</a>
                        </h5>
                        <h5>
                                <a href="https://www.facebook.com/SITECapituloMexico" target="_blank"
                                        rel="noopener noreferrer">{{ "footer.16" | translate }}</a>
                        </h5>

                        <h5>
                                <a href="https://www.gob.mx/sectur" target="_blank" rel="noopener noreferrer">{{
                                        "footer.18" | translate }}</a>
                        </h5>

                        <h5>
                                <a href="https://visitloscabos.travel" target="_blank" rel="noopener noreferrer">{{
                                        "footer.21" | translate }}</a>
                        </h5>
                        <h5>
                                <a href="https://www.gob.mx/fonatur" target="_blank" rel="noopener noreferrer">{{
                                        "footer.22" | translate }}</a>
                        </h5>
                        <h5>
                                <a href="https://alphatransfers.com.mx" target="_blank"
                                        rel="noopener noreferrer">Alphatransfers</a>
                        </h5>
                        <h5>
                                <a href="https://mireyabeltransalon.com" target="_blank"
                                        rel="noopener noreferrer">Mireya Beltran Salon & Spa</a>
                        </h5>
                </div>
        </div>

        <!--INICIA FOOTER MOBILE COLLAPSE-->
        <div class="d-md-none d-lg-block d-lg-none d-xl-block d-xl-none d-xxl-none d-xxl-block">
                <div class="accordion text-center justify-content-center" id="accordionPanelsStayOpenExample">
                        <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                aria-expanded="false" aria-controls="flush-collapseOne">
                                                {{ "footer.1" | translate }}
                                        </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <li class="mt-4">
                                                <ul> <a routerLink="/services" routerLinkActive="active">{{ "footer.2" |
                                                                translate }}</a></ul>
                                                <ul> <a routerLink="/ambiental" routerLinkActive="active">{{ "footer.26"
                                                                | translate }}</a></ul>



                                                <ul> <a routerLink="/hospitality" routerLinkActive="active">{{
                                                                "footer.3" | translate }}</a></ul>
                                                <ul> <a routerLink="/dmc" routerLinkActive="active">{{ "footer.4" |
                                                                translate }}</a></ul>
                                                <ul> <a routerLink="/incentives" routerLinkActive="active">{{
                                                                "footer.24" | translate }}</a></ul>


                                                <ul> <a routerLink="/wellness" routerLinkActive="active">{{ "footer.5" |
                                                                translate }}</a></ul>
                                                <ul> <a routerLink="/romance" routerLinkActive="active">{{ "footer.6" |
                                                                translate }}</a></ul>
                                                <ul> <a routerLink="/sourvey" routerLinkActive="active">{{ "footer.7" |
                                                                translate }}</a></ul>



                                        </li>
                                </div>
                        </div>
                        <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                {{ "footer.8" | translate }}
                                        </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingTwo">
                                        <div class="accordion-body">
                                                <li>
                                                        <ul><a routerLink="/legal" [queryParams]="{policy: 'terms'}"
                                                                        routerLinkActive="active">{{ "footer.11" |
                                                                        translate }}</a></ul>
                                                        <ul> <a routerLink="/legal" [queryParams]="{policy: 'privacy'}"
                                                                        routerLinkActive="active">{{ "footer.9" |
                                                                        translate }}</a></ul>


                                                        <ul> <a routerLink="/legal" [queryParams]="{policy: 'cookies'}"
                                                                        routerLinkActive="active">{{ "footer.10" |
                                                                        translate }}</a></ul>
                                                        <ul> <a routerLink="/legal" [queryParams]="{policy: 'ARCO'}"
                                                                        routerLinkActive="active">{{ "footer.13" |
                                                                        translate }}</a></ul>


                                                        <ul> <a routerLink="/policies"
                                                                        [queryParams]="{policy: 'cancelation'}"
                                                                        routerLinkActive="active">{{
                                                                        "footer.23" | translate }}</a></ul>

                                                        <ul> <a routerLink="/policies"
                                                                        [queryParams]="{policy: 'refund'}"
                                                                        routerLinkActive="active">{{ "footer.12" |
                                                                        translate }}</a></ul>

                                                        <ul> <a routerLink="/contracts" routerLinkActive="active">{{
                                                                        "footer.25" | translate }}</a></ul>
                                                </li>
                                        </div>
                                </div>
                        </div>
                        <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">{{
                                                "footer.14" | translate }}
                                        </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree">
                                        <div class="accordion-body">
                                                <li>
                                                        <ul><a routerLink="/faqs" routerLinkActive="active">{{
                                                                        "footer.19" | translate }}</a></ul>
                                                        <ul><a routerLink="/blog" routerLinkActive="active">{{
                                                                        "footer.20" | translate }}</a></ul>
                                                        <ul><a href="https://visitmexico.com/" target="_blank"
                                                                        rel="noopener noreferrer">{{ "footer.17" |
                                                                        translate }}</a></ul>
                                                        <ul><a href="https://amdemac.org/" target="_blank"
                                                                        rel="noopener noreferrer">{{ "footer.15" |
                                                                        translate }}</a></ul>
                                                        <ul><a href="https://www.facebook.com/SITECapituloMexico"
                                                                        target="_blank" rel="noopener noreferrer">{{
                                                                        "footer.16" | translate }}</a></ul>
                                                        <ul><a href="https://www.gob.mx/sectur" target="_blank"
                                                                        rel="noopener noreferrer">{{ "footer.18" |
                                                                        translate }}</a></ul>
                                                        <ul><a href="https://visitloscabos.travel" target="_blank"
                                                                        rel="noopener noreferrer">{{ "footer.21" |
                                                                        translate }}</a></ul>
                                                        <ul><a href="https://www.gob.mx/fonatur" target="_blank"
                                                                        rel="noopener noreferrer">{{ "footer.22" |
                                                                        translate }}</a></ul>
                                                        <ul><a href="https://alphatransfers.com.mx" target="_blank"
                                                                        rel="noopener noreferrer">Alphatransfers</a>
                                                        </ul>
                                                        <ul><a href="https://mireyabeltransalon.com" target="_blank"
                                                                        rel="noopener noreferrer">Mireya Beltran Salon &
                                                                        Spa</a></ul>
                                                </li>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
        <!--FIN FOOTER MOBILE COLLAPSE-->
        <div class="copy-bar">
                <div class="copy-text">&copy; {{ year }} AMI DMC Los Cabos. {{ "footer.legal" | translate }}. {{
                        "footer.dev" | translate }} <a href="https://webanta.com" target="_blank"
                                rel="noopener noreferrer">Webanta</a>
                </div>
        </div>
</div>
<div class="wave"></div>