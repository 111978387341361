<div class="thirdSection text-center">
    <div class="space">
        <p class="title-section mb-2">
            {{'staff.title' | translate}}
        </p>
        <div class="underline"></div>
    </div>
    <div class="container px-5">
        <div class="row">
            <div class="col-12 col-md-3" *ngFor="let location of locations">
                <img [src]="location.imgSrc" class="location-img" alt="">
                <p class="location-txt">{{location.name}}</p>
                <p>{{location.position}}</p>
            </div>
        </div>
    </div>